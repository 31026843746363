<template>
	<div class="wrap">
		<div class="tabs">
			<b>筛选：</b>
			<a href="javascript:;" v-for="(item,ind) in typeObj" :class="[type-1==ind ? 'ac' : '']" @click="clickType(ind)">{{item.name}}</a>
		</div>
		<ul class="list">
			<li class=" done buy " v-for="item in list" :key="item.id">
				<router-link :to="`/shopTemplate/${item.id}`">
					<div class="te-image">
						<img :src="item.photo">
					</div>
					<div class="te-info">
						<div class="left">
							<div class="te-price">￥{{item.money}}</div>
							<div class="te-name">{{item.title}}</div>
						</div>
						<div class="right">
							<div class="te-i">官方</div>
							<!-- {{item.order}} -->
							<div v-if="item.order==1" class="te-o te-y">已购买</div> <!-- 1是已购买-->
							<div v-else class="te-o te-n">请购买</div>
						</div>
					</div>
				</router-link>
			</li>
		</ul>
		<div class="bottom">
			<!-- <div class="zong">共{{total}}条</div> -->
			<el-pagination style="text-align: center;height: 80px;line-height: ;"
			  background
			  layout="prev, pager, next"
			  prev-text='上一页'
			  next-text='下一页'
			  :page-size='12'
			  :pager-count='2'
			  :current-page='currentPage'
			  @current-change='currentChange'
			  :total="total">
			</el-pagination>
		</div>
		
	</div>
</template>

<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				list: [],
				typeObj:[
					{
						name:'购买最多',
					},
					{
						name:'最新',
					},
					{
						name:'最热',
					},
					{
						name:'推荐',
					},
					{
						name:'免费',
					},
				],
				currentPage:1,
				type:1,
				total:1
			}
		},
		mounted() {
			this.loadData()
		},
		methods:{
			loadData(){
				var params={
					page:this.currentPage,//默认第一页 
					type:this.type//默认购买最多      1购买最多、2最新、3最热、4推荐、5免费
				}
				this.$axios.post('/getTemplateList',params).then(data => {
					this.total=data.total
					this.list = data.data;
				})
			},
			currentChange(val){//用户改变当前页的回调函数
				this.currentPage=val
				this.loadData()
			},
			clickType(index){//点击type
				this.type=index+1
				this.currentPage=1
				this.loadData()
			}
		}
	}
</script>

<style scoped>
	.wrap {
		width: 1600px;
		background: #f6f5f7;
	}

	.tabs {
		height: 70px;
		align-items: center;
		background: #fff;
		display: flex;
		padding: 0 20px;
		color: #282828;
		font-size: 14px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
	}

	.tabs a {
		color: #282828;
		margin-right: 13px;
		padding: 0 5px;
	}

	.tabs a.ac {
		color: #0194fe;
	}

	.list {
		margin-left: -20px;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 80px;
	}

	.list li {
		width: 345px;
		padding: 20px 20px 25px;
		margin-left: 20px;
		margin-top: 20px;
		background: #fff;
	}

	.te-image {
		height: 206px;
		overflow: hidden;
	}

	.te-image img {
		width: 100%;
	}

	.te-info {
		display: flex;
		margin-top: 29px;
	}

	.right {
		/* border: 1px solid red; */
	}

	.te-i {
		width: 60px;
		color: #fff;
		height: 20px;
		line-height: 20px;
		text-align: center;
		border-radius: 5px;
		font-size: 12px;
		background: #0194fe;
		margin-left: auto;
	}

	/* 购买 */
	.te-o {
		margin-top: 10px;
	}

	.te-y {
		width: 60px;
		color: #fff;
		height: 24px;
		line-height: 24px;
		text-align: center;
		border-radius: 5px;
		font-size: 14px;
		background: #33ba49;
		margin-left: auto;
	}

	/* 未购买 */
	.te-n {
		width: 60px;
		color: #fff;
		height: 24px;
		line-height: 24px;
		text-align: center;
		border-radius: 5px;
		font-size: 14px;
		background: #a31f22;
		margin-left: auto;
	}

	.left {
		flex: 1;
		overflow: hidden;
		margin-right: 20px;
	}

	.left {
		flex: 1;
		overflow: hidden;
		margin-right: 20px;
	}

	.te-price {
		font-size: 24px;
		color: #ea644a;
	}

	.te-name {
		font-size: 14px;
		color: #333333;
		margin-top: 14px;
	}
	/* 底部分页器 */
	.bottom{
	/* 	border: 1px solid red; */
	}
	.zong{
		/* border: 1px solid orangered; */
		display: inline-block;
	}
</style>
